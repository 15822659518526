<script>
import FormsPinboard from '@/components/forms/pinboard.vue'

export default {
  name: "ViewPin",

  components: {
    FormsPinboard
  },

  data() {
    return {
      pin: [],
      key: 0,

      step: 1,
      newPin: null,
      newPinConfirm: null,

      error: false,
      matchingError: false,
    }
  },

  computed: {
    isConfirmValid() {
      return this.key && parseInt(this.pin.join('')) === this.newPin
    },

    isValid() {
      let confirmValid = this.step === 2 ? this.isConfirmValid : true
      return this.key && this.pin.filter(p => this.$basil.isNil(p)).length === 0 && confirmValid
    },

    title() {
      if(this.step === 1) {
        return this.$t('conn3ct-wallet.change_pin_new')
      }

      if(this.step === 2) {
        return this.$t('conn3ct-wallet.change_pin_confirm_new')
      }

      return this.$t('conn3ct-wallet.change_pin_old')
    },
  },

  methods: {
    clearPin() {
      this.pin = []
      this.key++
    },

    onConfirm() {
      this.$wallet.newInteraction({ name: 'change-pin-confirm' })

      if(this.step === 1) {
        this.newPin = parseInt(this.pin.join(''))
        this.clearPin()
        this.step++
        return
      }

      if(this.step === 2) {
        this.newPinConfirm = parseInt(this.pin.join(''))
        this.clearPin()
        this.step++

        if(!this.$user.user.pinSet) {
          this.pin = [1, 2, 3, 4, 5]
          this.update()
        }

        return
      }

      this.update()
    },

    async update() {
      try {
        await this.$user.updatePin({ newPin: this.newPin, newPinConfirm: this.newPinConfirm, oldPin: parseInt(this.pin.join('')) })

        this.$notification({
          title: this.$t('conn3ct-wallet.pin_updated'),
          type: this.$pepper.Intent.SUCCESS
        })

        this.$router.push({ name: 'sayl-connect_user-settings' }).catch(() => {})
      } catch(e) {
        $console.error(e)
        this.error = true;
        this.clearPin();
      }
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$user.getUser()
    this.$bus.$on('back', () => this.$router.push({ name: 'sayl-connect_user-settings' }).catch(() => {}))
  },

  beforeDestroy() {
    this.$bus.$off('back')
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_user-settings' }"
    class="module-user pin"
    :subtitle="$t('conn3ct-wallet.change_pin_subtitle')"
    :title="title"
  >
    <ui-panel class="pin__body">
      <div class="pin__field">
        <forms-pinboard
          v-model="pin"
          @input="key++"
          class="pin__pinboard"
        />

        <span
          v-if="error"
          class="pin__error"
        >{{ $t('conn3ct-wallet.pin_error') }}</span>
      </div>

      <actions-button
        @click="onConfirm"
        :appearance="$pepper.Appearance.PRIMARY"
        class="pin__action"
        :disabled="!isValid"
      >{{ $t('conn3ct-wallet.confirm_action') }}</actions-button>
    </ui-panel>
  </layout-page>
</template>
